module.exports = {
  defaultTitle: 'Jonatan Lund',
  logo: 'https://jonatanl.me/favicon/favicon-512.png',
  author: 'jonatanl.me',
  url: 'https://jonatanl.me',
  legalName: 'Jonatan Lund',
  defaultDescription: 'My name is Jonatan Lund and I work as a full stack developer',
  socialLinks: {
    twitter: 'http://www.twitter.com/jonatanl92',
    github: 'https://github.com/jonatanl',
    linkedin: 'https://www.linkedin.com/in/jonatanlund/',
    gitlab: 'https://gitlab.com/devguides'
  },
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    twitter: '@jonatanl92'
  },
  foundingDate: '2020'
}
