import React from 'react'
import styled from 'styled-components'

const ChipStyles = styled.div`
  display: inline-block;
  height: 32px;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  padding: 0 15px;
  border-radius: 16px;
  background-color: #e4e4e4;
  margin-bottom: 10px;
  margin-right: 10px;
`

const Chip = ({ children }) => <ChipStyles>{children}</ChipStyles>

export default Chip
