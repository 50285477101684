const navbarItems = [
  {
    linkName: 'About',
    link: 'about'
  },
  {
    linkName: 'Work',
    link: 'work'
  },
  {
    linkName: 'Education',
    link: 'education'
  },
  {
    linkName: 'Skills',
    link: 'skills'
  },
  {
    linkName: 'Contact',
    link: 'contact'
  }
]

export default navbarItems
