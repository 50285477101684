import styled from 'styled-components'

const ContentWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  width: 90%;
  padding: 2rem 0;

  @media (min-width: 601px) {
    width: 90%;
  }

  @media (min-width: 993px) {
    width: 80%;
  }
`

export default ContentWrapper
