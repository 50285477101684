import React from 'react'
import { Footer } from 'components/theme'
import { Global } from './styles'
import './fonts.css'

const Layout = ({ children }) => (
  <>
    <Global />
    {children}
    <Footer />
  </>
)

export default Layout
