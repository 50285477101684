const categories = [
  {
    name: 'Front-end',
    skills: ['HTML', 'CSS', 'React', 'Gatsby', 'Android']
  },
  {
    name: 'Backend',
    skills: ['DevOps', '.NET Core', 'Node.JS']
  },
  {
    name: 'Software and Services',
    skills: [
      'Linux',
      'Docker',
      'Elasticsearch',
      'Nginx',
      'Gitlab',
      'Github',
      'MongoDB',
      'PostgreSQL',
      'MSSQL',
      'Ghost Blog',
      'Azure DevOps',
      'GraphQL',
      'REST'
    ]
  },
  {
    name: 'Programming languages',
    skills: ['C#', 'Java', 'JavaScript', 'Python']
  },
  {
    name: 'Cloud Providers',
    skills: ['DigitalOcean', 'Azure', 'AWS']
  }
]

export default categories
