import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { NavbarItems } from '../../../../data'
import { Wrapper } from './styles'

const NavbarLinks = ({ desktop }) => (
  <Wrapper desktop={desktop}>
    {NavbarItems.map(({ linkName, link }) => (
      <AnchorLink key={linkName} href={`#${link}`}>
        {linkName}
      </AnchorLink>
    ))}
  </Wrapper>
)

export default NavbarLinks
