const work = [
  {
    companyName: 'Bouvet',
    companyLink: 'https://www.bouvet.no/',
    position: 'Consultant',
    location: 'Trondheim, Norway',
    from: '2017',
    to: 'present'
  },
  {
    companyName: 'Junior Consulting AS',
    companyLink: 'https://www.jrc.no/',
    position: 'Consultant',
    location: 'Trondheim, Norway',
    from: '2016',
    to: '2017'
  },
  {
    companyName: 'Bekk Consulting AS',
    companyLink: 'https://www.bekk.no/',
    position: 'Consultant, summer internship',
    location: 'Oslo, Norway',
    from: '2016',
    to: null
  },
  {
    companyName: 'Knowit',
    companyLink: 'https://www.knowit.no/',
    position: 'Consultant, summer internship',
    location: 'Oslo, Norway',
    from: '2015',
    to: null
  },
  {
    companyName: 'Zedge Europe AS',
    companyLink: 'https://www.zedge.net/',
    position: 'Android developer, summer internship and part time',
    location: 'Trondheim, Norway',
    from: '2014',
    to: null
  },
  {
    companyName: 'Studentmediene i Trondheim',
    companyLink: 'https://studentmediene.no/',
    position: 'Web developer. Studentmediene i Trondheim is a voluntary student organization',
    location: 'Trondheim, Norway',
    from: '2012',
    to: '2014'
  },
  {
    companyName: 'EVRY ASA',
    companyLink: 'https://www.evry.com/en/',
    position: 'Consultant, summer internship',
    location: 'Trondheim, Norway',
    from: '2013',
    to: null
  }
]

export default work
